<template>
  <vue-fab
    ref="buttonAction"
    class="voffice-actionButton"
    fabItemAnimate="alive"
    :class="getClassPosition"
    :mainBtnColor="mainBtnColor"
    :scrollAutoHide="false"
    :clickAutoClose="false"
    :text="title"
    :iconImage="beaconLogo ? beaconLogo : getDefaultIcon"
    :colorText="color"
    :style="getCustomPositionStyle"
    :key="reload"
  >
    <fab-item :idx="1">
      <!-- :style="`box-shadow: 0px 1px 9px ${color}`" -->
      <div class="voffice-MainMenu">
        <Header :color="color" :title="title" :headerIcon="getHeaderIcon" :language="getLanguage"/>
        <Container
          :beaconSettings="beaconSettings"
          :teams="teams"
          :showTeams="showTeams"
          :namespace="namespace"
          :color="color"
          :setReference="setReference"
          :users="users"
          :language="getLanguage"
          ref="vOfficeContainer"
        />
        <Footer :beaconLogo="beaconLogo" :footerIcon="getDefaultIcon" :language="getLanguage"/>
      </div>
    </fab-item>
  </vue-fab>
</template>
<script>
import Header from "./components/Header";
import Container from "./components/Container";
import Footer from "./components/Footer";
export default {
  components: { Header, Container, Footer },
  props: ["icon", "namespace", "position", "language"],
  data() {
    return {
      mainBtnColor: "transparent",
      vofficeContainerRef: null,
      users: [],
      title: "",
      color: "",
      showTeams: false,
      teams: [],
      beaconSettings: {},
      beaconLogo: null,
      reload: false,
      // customStyle: "bottom: 110px !important; right: 150px !important;",
      // classPosition: "positionRight",
    };
  },
  mounted() {
    this.contacts().then(() => {
      if (this._destroyed) return;
      this._unwatch = this.$watch('$refs.buttonAction.active', (active) => {
        this._interval && clearInterval(this._interval);
        if (active && !this._destroyed) {
          this.contacts().then(() => {
            this._interval && clearInterval(this._interval);
            if (this.$refs.buttonAction.active && !this._destroyed) {
              this._interval = setInterval(this.contacts.bind(this), 20000);
            }
          });
        }
      });
    });
  },
  beforeDestroy() {
    this._destroyed = true;
    this._unwatch && this._unwatch();
  },
  destroyed() {
    this._interval && clearInterval(this._interval);
  },
  methods: {
    getShowUser(contact) {
      if (contact.user.connected && !contact.user.isInCall) {
        return true;
      } else if (
        contact.user.connected &&
        contact.user.isInCall &&
        contact.user.allowBeaconWhileInCall == "active"
      ) {
        return true;
      } else if (
        contact.user.connected &&
        contact.user.isInCall &&
        contact.user.allowBeaconWhileInCall !== "active" &&
        this.beaconSettings.beaconInactiveUsers !== "hidden"
      ) {
        return true;
      } else if (
        !contact.user.connected &&
        this.beaconSettings.beaconInactiveUsers !== "hidden"
      ) {
        return true;
      }
      return false;
    },
    setReference() {
      this.vofficeContainerRef = this.$refs.vOfficeContainer;
    },
    async contacts() {
      const result = await fetch(this.namespace + "/api/beaconUsers").then(
        (r) => r.json()
      );
      const temporalTeams = [];
      if (result.success) {
        this.users = result.users;
        if (result.beaconSettings) {
          this.beaconSettings = result.beaconSettings;
          this.title = result.beaconSettings.beaconTitle;
          this.color = result.beaconSettings.beaconColor;
          this.showTeams = result.beaconSettings.showTeams;
          this.beaconLogo = result.beaconSettings.beaconLogo;
        }
        if (result.teams) {
          result.teams.forEach((team) => {
            const tmpTeamObj = { team: team.team, teamMembers: [] };
            this.users.forEach((user) => {
              if (
                (team.teamMembers &&
                  team.teamMembers.indexOf(user.uuid) !== -1) ||
                team.supervisor.indexOf(user.uuid) !== -1
              ) {
                if (
                  tmpTeamObj.teamMembers.indexOf(user.uuid) === -1 &&
                  this.getShowUser(user)
                ) {
                  tmpTeamObj.teamMembers.push(user);
                }
              }
            });
            if (tmpTeamObj.teamMembers.length > 0) {
              temporalTeams.push(tmpTeamObj);
            }
          });
        }
        this.teams = temporalTeams;
      }
    },
  },
  computed: {
    getClassPosition() {
      const position = this.position || JSON.stringify({ position: "right" });
      const data = JSON.parse(position);
      if (data) {
        if (data.position == "right") {
          return "positionRight";
        } else if (data.position == "left") {
          return "positionLeft";
        } else {
          return "positionRight";
        }
      } else {
        return "positionRight";
      }
    },
    getCustomMenuPositionStyle() {
      const position = this.position || JSON.stringify({ position: "right" });
      const data = JSON.parse(position);
      const innerWidth = window.innerWidth;
      let bottom = 110;
      if (this.position) {
        bottom = parseInt(bottom) + parseInt(data.bottomPx || 0);
      } else {
        if (innerWidth < 600) {
          bottom = 170;
        }
      }
      return `bottom: ${bottom}px !important;`;
    },
    getCustomPositionStyle() {
      const position = this.position || JSON.stringify({ position: "right" });
      const data = JSON.parse(position);
      const innerWidth = window.innerWidth;
      let bottom = 110;
      let right = 150;
      let left = 50;
      if (this.position) {
        if (data.position == "right") {
          bottom = parseInt(bottom) + parseInt(data.bottomPx || 0);
          right = parseInt(right) + parseInt(data.rightPx || 0);
          if (innerWidth < 600) {
            bottom = 170;
          }
          return `bottom: ${bottom}px !important; right: ${right}px !important;`;
        } else if (data.position == "left") {
          bottom = parseInt(bottom) + parseInt(data.bottomPx || 0);
          left = parseInt(left) + parseInt(data.leftPx || 0);
          return `bottom: ${bottom}px !important; left: ${left}px !important;`;
        } else {
          if (innerWidth < 600) {
            bottom = 170;
          }
          return `bottom: ${bottom}px !important;right: ${right}px !important;`;
        }
      } else {
        if (innerWidth < 600) {
          bottom = 170;
        }
        return `bottom: ${bottom}px !important; right: ${right}px !important;`;
      }
    },
    getHeaderIcon() {
      return this.icon || this.namespace + "/assets/icon.png";
    },
    getDefaultIcon() {
      return this.namespace + "/assets/icon.png";
    },
    getLanguage() {
      return this.language || navigator.language;
    },
  },
};
</script>
<style scoped lang="css">
.voffice-actionButton.positionRight .voffice-MainMenu {
  right: 3%;
}
.voffice-actionButton.positionLeft .voffice-MainMenu {
  left: 3%;
}
.voffice-actionButton .voffice-icon {
  position: unset !important;
}
.voffice-actionButton {
  line-height: 1.42857143 !important;
}
.voffice-MainMenu {
  cursor: default;
  position: fixed;
  /* min-width: calc(100% / 5); */
  min-width: 365px;
  max-width: 365px;
  bottom: 11%;
  height: 450px;
  max-height: 450px;
  /* border: 1px solid #2a3133; */
  background-color: #ffffff;
  font-family: Roboto, sans-serif;
  /* font-family: "Muli", sans-serif; */
  border-radius: 4px;
  box-shadow: 0px 1px 9px #a4a4a4;
}
.voffice-actionButton > .fab-item {
  display: flex;
  justify-content: center;
  top: -50px;
  width: 0% !important;
}
</style>
<style>
@media all {
  .voffice-actionButton .fab-cantainer,
  .voffice-actionButton .voffice-icon,
  .voffice-actionButton .fab-cantainer * {
    margin-top: unset !important;
  }
}
.voffice-actionButton.positionRight.fab-main-container {
  /* right: 150px !important; */
  /* bottom: 110px !important; */
}
.voffice-actionButton .fab-shadow {
  box-shadow: none !important;
}
.voffice-actionButton .voffice-title {
  font-family: Roboto, sans-serif;
  font-size: 14px !important;
  line-height: 1 !important;
  margin-top: 10px !important;
}
.voffice-actionButton.positionLeft.fab-main-container {
  /* left: 2% !important; */
  right: unset !important;
}
.voffice-actionButton .fab {
  box-shadow: none !important;
}
/* .voffice-actionButton .fab-item {
  transition: none !important;
} */
.voffice-actionButton .touchStyle,
.voffice-actionButton .fab-cantainer.fab-item.fab-shadow {
  background: transparent !important;
}
@media (max-width: 600px) {
  .voffice-actionButton.positionRight.fab-main-container {
    right: 150px !important;
    /* bottom: 12% !important; */
  }
}
</style>